import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

// Connects to data-controller="sortable-form"
export default class extends Controller {
  connect() {
    let form = $(this.element).closest("form")[0]

    $(this.element).each((i, element) => {
      Sortable.create(element, {
        handle: '.sortable-handle',
        onUpdate: (_event) => {
          form.requestSubmit()
        },
      })
    })
  }
}


