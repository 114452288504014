import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-visibility"
export default class extends Controller {
  static targets = ["fieldWrapper"];

  connect() {
    this.updateFieldsVisibility();
  }

  // Triggered when the inputs (checkboxes/radio buttons) are changed
  toggleFields(event) {
    this.updateFieldsVisibility();
  }

  updateFieldsVisibility() {
    const selectedValues = this.getSelectedInputValues();

    // Loop through all field wrappers
    this.fieldWrapperTargets.forEach((wrapper) => {
      // Get the data-value attribute of the wrapper
      const wrapperValue = wrapper.getAttribute("data-value");
      let selectField = $(wrapper).find('select');

      // Toggle visibility based on the selected radio value
      if (selectedValues.includes(wrapperValue)) {
        $(wrapper).removeClass('hide')
        if (selectField.val() == null) {
          selectField.val(selectField.find("option:first").val());
          selectField.trigger('change');
        }
      } else {
        $(wrapper).addClass('hide')
        selectField.val('');
      }
    });
  }

  getSelectedInputValues() {
    const selectedInputs = document.querySelectorAll('input:checked');
    return Array.from(selectedInputs).map(element => element.value)
  }
}
