$(document).on('turbo:load', () => {
  $(document.body).on('change', '#similar-job-roles', (event) => {
    let element = $(event.target)
    let selected = element.val();
    let fetchUrl = element.data('fetchUrl');
    let checkedQuestions = $('#similar-questions').find('input:checkbox:checked').map((i, el) => el.value).get();
    let fetchPath = fetchUrl + `?job_role_ids=${selected}&similar_question_ids=${checkedQuestions}`


    fetch(fetchPath)
      .then((data) => data.text())
      .then((html) => {
        $('#similar-questions').html(html)
      });

  });

  $('[data-toggle="popover"]').popover();
});
