import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-options"
export default class extends Controller {
  static targets = [ "associationLink", "option" ]

  toggleOptions(event) {
    let value = $(event.target).val();
    if (value == 'single_select') {
      this.showAssociationLink()
    } else {
      this.hideAssociationLink()
    }
  }

  showAssociationLink() {
    $(this.associationLinkTarget).show()
    this.optionTargets.forEach((element, index) => {
      $(element).removeClass('hide')
    })
  }

  hideAssociationLink() {
    $(this.associationLinkTarget).hide()
    this.optionTargets.forEach((element, index) => {
      $(element).addClass('hide')
      $(element).find('input').val('')
    })
  }
}
