import { Controller } from "@hotwired/stimulus"
import introJs from 'intro.js'

// Connects to data-controller="feature-tour"
// This controller can be dynamically enabled/disabled by setting data-feature-tour-enabled-value
export default class extends Controller {
  static values = {
    enabled: Boolean
  }

  connect() {
    if (!this.enabledValue) {
      return;
    }

    let introObject = introJs();

    introObject.setOptions({
      dontShowAgain: true,
      // This is cookie name, to use IntroJS tour on multiple pages, and wish to enable the "Don't show again" checkbox,
      // it must use the dontShowAgainCookie option to set a unique cookie name on each page where using IntroJS.
      dontShowAgainCookie: "introjs-dontShowAgain-4",
      showProgress: true,
      showBullets: false,
      steps: [
        {
          title: 'New Dashboard',
          intro: "Welcome to the new SmartRank dashboard that displays up-to-date information across candidates and requisitions, and highlights your most important next steps."
        },

        // Text Messages
        {
          element: document.querySelector('#navbar-dropdown-hiring-funnel'),
          intro: 'As always, you can get to your hiring funnel here, and you can even bookmark the page.'
        },

        // Done
        {
          title: "That's it!",
          element: document.querySelector('.card__image'),
          intro: "Email support@smartrank.ai with any questions"
        }
      ]
    })

    introObject.onexit(function() {
      introObject.setDontShowAgain(true);
      $('#navbar-dropdown-candidates-items').removeClass('show');
    });

    introObject.onbeforechange(function(targetElement) {
      if ($(targetElement).attr('id') == 'navbar-dropdown-hiring-funnel') {
        $('#navbar-dropdown-candidates-items').addClass('show');
      }
    });

    // hide the useles "Don't show again" checkbox (we automatically set the cookie when exiting the tour)
    introObject.onafterchange(function(targetElement) {
      $('.introjs-dontShowAgain').hide()
    });

    introObject.start();
  }
}
