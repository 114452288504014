import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  reset() {
    this.element.reset()
  }

  submitOnEnter(event) {
    if(event.which == 13 && !event.shiftKey){
      Rails.fire(event.currentTarget.form, 'submit');
    }
  }
}
