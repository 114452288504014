import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    // Skip reload if a redirect is expected. The window.expectingRedirect property should only ever be set in the
    // onclick of a link that is 100% guaranteed to redirect, otherwise the page will never reload.
    if (!window.expectingRedirect) {
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('reload'); // Remove the parameter
      window.location.search = urlParams.toString(); // Reload the page with new query string
      // updating window.location.search reloads the page, so we don't need to do  window.location.reload(true);
    }
  }
}
