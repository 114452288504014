import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question-comments"
export default class extends Controller {
  static targets = [ 'questionUpdateListener' ]

  questionUpdateListenerTargetConnected(element) {
    let questionId = $(element).data('questionId')
    let count = $(element).data('count')
    let badgeContainers = $(".question-comments-badge-"+questionId)

    let stimulusController = this
    badgeContainers.each(function (i) {
      stimulusController.updateCommentCount($(this), count)
    })
  }

  // redraws the given badgeContainer based on commentCount
  updateCommentCount(badgeContainer, commentCount) {
    // empty makes the badge disappear when commentCount is 0.
    badgeContainer.empty()

    // rebuild the badge contents if the badge needs to be displayed
    if (commentCount > 0) {
      let badge = document.createElement('span')
      badge.className = "badge badge-pill badge-danger"
      badge.textContent = commentCount

      let badgeText = document.createElement('span')
      badgeText.className = "text-danger ml-1"
      badgeText.textContent = commentCount > 1 ? 'Comments' : 'Comment'

      badgeContainer.append(badge)
      badgeContainer.append(badgeText)
    }
  }
}
