function focusQuestion(question) {
  question.removeClass('question-unfocused').addClass('question-focused');

  if (questionAnswered(question)) {
    enableNextQuestion();
  } else {
    disableNextQuestion();
  }

  if (hasPrevQuestion(question)) {
    enablePrevQuestion();
  } else {
    disablePrevQuestion();
  }
}

function unfocusQuestion(question) {
  question.removeClass('question-focused').addClass('question-unfocused');
}

function hasPrevQuestion(question) {
  return question.prev('.question').length > 0
}

function hasNextQuestion(question) {
  return question.next('.question').length > 0
}

function navigatePreviousQuestion(question) {
  if (hasPrevQuestion(question)) {
    unfocusQuestion(question);
    question = question.prev('.question');
    focusQuestion(question);
  }
  updateQuestionCount();
}

function navigateNextQuestion(question) {
  if (hasNextQuestion(question)) {
    if (questionAnswered(question)) {
      unfocusQuestion(question);
      let nextQuestion = question.next('.question');
      focusQuestion(nextQuestion);
    }
    updateQuestionCount();
  } else {
    question.parents('form').submit()
  }
}

function questionAnswered(question) {
  // determine if the question has an answer with the .active class or we're on the last fill-in question
  return question.find('.active').length > 0 || question.hasClass('question-optional')
}

function disablePrevQuestion() {
  $('#previous-question').prop('disabled', true);
}

function enablePrevQuestion() {
  $('#previous-question').prop('disabled', false);
}

function disableNextQuestion() {
  $('#next-question').prop('disabled', true);
}

function enableNextQuestion() {
  $('#next-question').prop('disabled', false);
}

function updateQuestionCount() {
  let current = $('.question').index($('.question-focused')) + 1;
  let total = $('.question').length;

  $('#question-count').text(`Question ${current} of ${total}`);
}

$(document).on('click', '#ok-go', function () {
    focusQuestion($('.question:first'))

    $('#buttons').toggle()
});

$(document).on('change', '.answer input', function(event) {
  let checked = $(event.target).is(':checked');
  let noneChecked = $(event.target).parent().siblings('.answer').find('input:checked').length == 0;

  // don't automatically navigate if multiple answers are possible
  if ($(this).parents('.question').hasClass('multiple-answers')) {
    $('#nav-buttons button').each(function() {
      $(this).prop('disabled', !checked && noneChecked);
    });

    return true;
  }

  $('#nav-buttons button').each(function() {
    $(this).prop('disabled', true);
  });
  setTimeout(() => {
    navigateNextQuestion($(this).closest('.question'));
  }, 500);
});

$(document).on('click', '#previous-question', function() {
  let currentQuestion = $('.question-focused');
  navigatePreviousQuestion(currentQuestion);
})

$(document).on('click', '#next-question', function() {
  let currentQuestion = $('.question-focused');
  navigateNextQuestion(currentQuestion);
})

$(document).on('turbo:load', () => {
    if (questionAnswered($('.question-focused'))) {
        enableNextQuestion();
    }
});
