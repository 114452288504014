$(document).on('turbo:load', () => {
  // bulk action checkboxes
  $(document.body).on('click', '.check-all', (event) => {
    let form = $(event.target).closest("form")
    form.find('input:checkbox').not(":disabled").prop('checked', event.target.checked);
    $('.bulk-item').prop("disabled", !event.target.checked)
  })

  $(document.body).on('click', ':checkbox:not(.check-all)', (_event) => {
    $('.bulk-item').prop("disabled", $(':checkbox:not(.check-all)').filter(':checked').length == 0);
  });

  // apply tooltip to anything with data-toggle="tooltip"
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });

  // remove tooltips after they're clicked
  $(document.body).on('click', '[data-toggle="tooltip"]', (event) => {
    $(event.currentTarget).tooltip('dispose');
  });
});
