import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-info-fields"
export default class extends Controller {
  connect() {
    let thisController = this;
    // prevent the last contact point from being deleted if it is required
    $(thisController.element).on('cocoon:before-remove', function (event, removedItem) {
      // Prevent deletion of the last contact point if phone is required
      if ($('[data-controller="contact-point"]:visible').length == 1) {
        // removing the last item is not allowed if it has any ci-required fields
        if (removedItem.find('.ci-required').length > 0) {
          // clear out the info element (will be highlighted if phone required)
          let infoElement = $(removedItem).find('input:text');
          infoElement.val('')
          let iti = window.intlTelInputGlobals.getInstance(infoElement[0]);
          if (typeof iti !== 'undefined') {
            iti.destroy()
          }

          // clear out the preferred contact checkbox (will be highlighted if preferred_contact is required)
          $(removedItem).find('input:checkbox').prop( "checked", false );

          // prevent the item from being removed
          event.preventDefault();

          thisController.validateElements('.preferred-contact')
        }
      }
    });

    $(thisController.element).on('cocoon:after-remove', function (event, removedItem) {
      // Revalidate in case deletion of the only preferred contact point invalidated remaining
      thisController.validateElements('.preferred-contact')
      thisController.validateElements('.phone-channel')
    });

    $(thisController.element).on('cocoon:after-insert', function() {
      thisController.validateElements('.phone-channel')
    });
  }

  validateElements(className) {
    if ($(className).length > 0) {
      $(className).valid()
    }
  }
}
