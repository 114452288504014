import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scorecard"
export default class extends Controller {
  static targets = [ "score", "total" ]

  scoreChange(event) {
    let totalScores = this.scoreTargets.flatMap(score => score.value ? score.value : [])
    let totalPossibleScores = this.scoreTargets.flatMap(score => score.value ? score.dataset.possibleScore : [])

    let averageScore = this.averageScores(totalScores)
    let averagePossibleScore = this.averageScores(totalPossibleScores)

    this.updateTotal(averageScore, averagePossibleScore)
  }

  updateTotal(total, possibleScore) {
    this.totalTarget.value = `${total}/${possibleScore}`
  }

  averageScores(array) {
    let total = array.reduce((accumulator, score) => parseInt(accumulator || 0) + parseInt(score || 0))
    return Math.round((total/array.length) * 100) / 100
  }
}
