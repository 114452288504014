function addAlert(message) {
  let alert = document.createElement('div');
  $(alert).addClass('alert alert-danger').text(message).prependTo('.main-content-box');
}

function removeAlert() {
  $('.alert.alert-danger').remove();
}

$(document).on('click', '.cancel', function(event) {
  $('#section-form').html('');
  $('#sections').show();
  $("#add-section").show();
  $("#nav-buttons").show();

  $(event.target).closest(".modal").modal("hide");
});
