import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conversation"
export default class extends Controller {
  static targets = [ 'textMessage' ]

  connect() {
    this.scrollToCurrent()
  }

  textMessageTargetConnected(element) {
    this.scrollToCurrent()
    this.markAsRead(element)
  }

  scrollToCurrent() {
    let objDiv = document.getElementById("text_messages");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  markAsRead(element) {
    if ($(element).hasClass('unread')) {
      let model_id = $(element).attr('id').replace( /^\D+/g, '')
      let url = `/text_messages/${model_id}/mark_read`
      $.ajax({url: url, type: 'PATCH', beforeSend: $.rails.CSRFProtection})
    }
  }
}
