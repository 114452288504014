import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time-field"
export default class extends Controller {
  static targets = [ "startTime", "endTime" ]
  // data-time-field-meeting-offset-value
  static values = { meetingOffset: Number }

  startTimeTargetConnected(element) {
    $(element).timepicker({
      scrollDefault: 'now',
      step: 15,
      timeFormat: 'g:i A'
    });

    if (!element.value) {
      $(element).timepicker('setTime', this.roundTime())
    }
  }

  endTimeTargetConnected(element) {
    let startTimeElement = $(this.startTimeTarget)
    $(element).timepicker({
      durationTime: function() { return startTimeElement.val() },
      scrollDefault: 'now',
      showDuration: true,
      step: 15,
      timeFormat: 'g:i A'
    });

    if (!element.value) {
      $(element).timepicker('setTime', this.roundTime(this.meetingOffsetValue))
    }
  }

  updateEndTime(event) {
    let startTime = $(event.currentTarget).timepicker('getTime')
    let endTime = new Date(startTime.getTime() + this.meetingOffsetValue*60000)
    $(this.endTimeTarget).timepicker('setTime', endTime)
  }

  roundTime(offset) {
    let d = new Date();
    d.setMinutes (d.getMinutes() + 30);
    d.setMinutes (0);
    if (offset) {
      d.setMinutes(d.getMinutes() + offset)
    }
    return d;
  }
}
