import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-taggable"
export default class extends Controller {
  static values = {
    users: Array,
    usernameRegex: String
  }

  static targets = [ 'field', 'menu' ]

  connect() {
    this.fieldTarget.insertAdjacentHTML('afterend', '<div class="dropdown-menu"  data-user-taggable-target="menu"></div>')

    $(this.fieldTarget).on('keypress', function(event) {
      // don't allow enter key to do anything
      if (event.which == '13') {
        event.preventDefault();
      }
    })
  }

  parse(_event) {
    if (this.usernameMatches().length > 0) {
      this.usernameMatches().forEach(match => {
        let usernameMatchString = match[1];
        this.displayUsers(usernameMatchString)
      })
    } else {
      this.removeDropdown()
    }
  }

  displayUsers(usernameMatchString) {
    let users = this.usersValue // ["@usercanonicalname, Full Name", "..."]
    users = users.filter(user => user.includes(usernameMatchString));
    users = users.map(user => user.split(', ')) // [[@usercanonicalname, Full Name], [...]]

    // determine what names are already in the value field and don't show them
    users = users.filter(([username, _userFullName]) => !this.fieldTarget.value.includes(username))

    if (users.length > 0) {
      $(this.menuTarget).html(this.menuContent(users, usernameMatchString))
      $(this.fieldTarget).dropdown()
      $(this.fieldTarget).dropdown('show')
    } else {
      this.removeDropdown()
    }
  }

  menuContent(users, usernameMatchString) {
    let listItemFunction = this.listItem
    return users.slice(0, 4).map(user => listItemFunction(user, usernameMatchString))
  }

  listItem([username, userFullName], usernameMatchString) {
    return `<a class="dropdown-item" href="javascript:void(0)" data-action="user-taggable#insertUser" data-user-taggable-username-param="${username}" data-user-taggable-matchstring-param="${usernameMatchString}"><b>@${username}</b> - ${userFullName}</a>`
  }

  insertUser({ params: { username, matchstring } }) {
    let regexp;

    if (matchstring.length > 0) {
      regexp = new RegExp(`@${matchstring}\\b`, 'gi')
    } else {
      regexp = new RegExp(`@$`, 'gi')
    }
    this.fieldTarget.value = this.fieldTarget.value.replace(regexp, `@${username} `)

    this.resetMenu()
  }

  resetMenu() {
    $(this.menuTarget).html('')
    this.removeDropdown()
    $(this.fieldTarget).focus()
  }

  // returns an array of matches
  usernameMatches() {
    let regexp = new RegExp(this.usernameRegexValue, 'giu');
    return [...this.fieldTarget.value.matchAll(regexp)]
  }

  removeDropdown() {
    $(this.fieldTarget).dropdown('hide')
    $(this.fieldTarget).dropdown('dispose')
  }
}
