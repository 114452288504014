import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="select-position-definition"
export default class extends Controller {
  connect() {
  }

  // $('#position_definition_id')
  changeSelection(event) {
    let position_definition_id = $(event.target).find(":selected").val();

    // change the questions count
    $('.position-definition-question-count').addClass('hide')
    $(`#position-definition-${position_definition_id}-question-count`).removeClass('hide')

    // change the upgrade status
    $('.position-definition-upgrade-status').addClass('hide')
    $(`#position-definition-${position_definition_id}-upgrade-status`).removeClass('hide')

    // show the upgrade checkboxes if necessary
    if ($(`#position-definition-${position_definition_id}-upgrade-status`).data('is-upgradeable')) {
      $('#position-definition-upgrade-checkboxes').removeClass('hide')
    } else {
      $('#position-definition-upgrade-checkboxes').addClass('hide')
    }
  }
}
