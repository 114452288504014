import { Controller } from "@hotwired/stimulus"
import introJs from "intro.js";

// Connects to data-controller="candidate-feature-tour"
// This controller can be dynamically enabled/disabled in the rails controller via @show_candidate_feature_tour

export default class extends Controller {
  static values = {
    enabled: Boolean
  }

  connect() {
    if (!this.enabledValue) {
      return;
    }

    let introObject = introJs();

    introObject.setOptions({
      dontShowAgain: true,
      // This is cookie name, to use IntroJS tour on multiple pages, and wish to enable the "Don't show again" checkbox,
      // it must use the dontShowAgainCookie option to set a unique cookie name on each page where using IntroJS.
      dontShowAgainCookie: "introjs-candidate-dontShowAgain-1",
      showProgress: true,
      showBullets: false,
      steps: [
        {
          title: 'Welcome to SmartMatch™',
          intro: "Check out our novel matching features"
        },

        // Jobs
        {
          element: document.querySelector('#navbarDropdownJobs'),
          intro: "Start here to find your dream job"
        },

        {
          element: document.querySelector('#introjs-search-jobs-menu-item'),
          intro: "Find the jobs that match you best using our sorting, filtering, and search tools"
        },

        // Alerts
        {
          element: document.querySelector('#navbarDropdownAlerts'),
          intro: "Use alerts to be notified instantly when new jobs are posted"
        },
        {
          element: document.querySelector('#introjs-alerts-feed-menu-item'),
          intro: "Alerts Feed contains newly-posted jobs that match your interests"
        },
        {
          element: document.querySelector('#introjs-manage-alerts-menu-item'),
          intro: "Manage the types of alerts you want to receive here"
        },

        // Matches
        {
          element: document.querySelector('#navbarDropdownMatches'),
          intro: "Matches keep track of the various ways you've matched with various companies."
        },
        {
          element: document.querySelector('#introjs-job-applications-menu-item'),
          intro: "Manage all your job applications across multiple companies in one place."
        },
        {
          element: document.querySelector('#introjs-recommendations-menu-item'),
          intro: "You may receive Recommendations to apply for jobs you match with. Manage those here."
        },
        {
          element: document.querySelector('#introjs-match-requests-menu-item'),
          intro: "You may receive Match Requests to see if you're a good match for a job without having to apply. Manage those here."
        },

        // Done
        {
          title: "That's it!",
          element: document.querySelector('.card__image'),
          intro: "Happy job hunting!"
        }
      ]
    })

    introObject.onexit(function () {
      introObject.setDontShowAgain(true);
      $('#navbar-dropdown-jobs').removeClass('show');
      $('#navbar-dropdown-alerts').removeClass('show');
      $('#navbar-dropdown-matches').removeClass('show');
    });

    introObject.onbeforechange(function (targetElement) {
      if ($(targetElement).attr('id') == 'navbarDropdownJobs') {
        $('#navbar-dropdown-jobs').addClass('show');
      }

      if ($(targetElement).attr('id') == 'navbarDropdownAlerts') {
        $('#navbar-dropdown-jobs').removeClass('show');
        $('#navbar-dropdown-alerts').addClass('show');
      }

      if ($(targetElement).attr('id') == 'navbarDropdownMatches') {
        $('#navbar-dropdown-alerts').removeClass('show');
        $('#navbar-dropdown-matches').addClass('show');
      }
    });

    // hide the useles "Don't show again" checkbox (we automatically set the cookie when exiting the tour)
    introObject.onafterchange(function (targetElement) {
      $('.introjs-dontShowAgain').hide()
    });

    introObject.start();
  }
}
