import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rater"
export default class extends Controller {
  static targets = [ 'channel', 'infoField' ]

  connect() {
    this.toggleInfoPhoneField()
  }

  channelChange(event) {
    this.toggleInfoPhoneField()
  }

  showTextHelp() {
    $("#cell-phone-help").show()
    $(this.infoFieldTarget).addClass('with-help')
  }

  hideTextHelp() {
    $("#cell-phone-help").hide()
    $(this.infoFieldTarget).removeClass('with-help')
  }

  toggleInfoPhoneField() {
    if (this.channelTarget.value == 'text') {
      this.showTextHelp()
    } else {
      this.hideTextHelp()
    }
  }
}
