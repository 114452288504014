import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

// Connects to data-controller="contact-point"
export default class extends Controller {
  static targets = [ 'channel', 'infoField' ]

  connect() {
    this.toggleInfoPhoneField()
  }

  channelChange(event) {
    this.toggleInfoPhoneField()
    this.revalidateChannels(event)
  }

  infoChange(_event) {
    if (this.isPhoneField()) {
      let iti = window.intlTelInputGlobals.getInstance(this.infoFieldTarget);
      $(this.infoFieldTarget).val(iti.getNumber());
    }
  }

  revalidateChannels(_event) {
    this.validateElements('.phone-channel')
  }

  revalidatePreferred(event) {
    // ensure only one preferred contact checkbox is checked
    if (event.target.checked) {
      $('.independent-checkbox').not(event.target).prop('checked', false);
    }

    // validate all the contact points on the page as changes to event.target could clean up their invalid state
    this.validateElements('.preferred-contact')
  }

  toggleInfoPhoneField() {
    if (this.isPhoneField()) {
      intlTelInput(this.infoFieldTarget, {
        hiddenInput: 'international_phone_string',
      })
    } else {
      let iti = window.intlTelInputGlobals.getInstance(this.infoFieldTarget);
      if (typeof iti !== 'undefined') {
        iti.destroy()
        $(this.infoFieldTarget).attr('placeholder', '')
      }
    }
  }

  isPhoneField() {
    return ['phone', 'text'].includes(this.channelTarget.value)
  }

  validateElements(className) {
    if ($(className).length > 0) {
      $(className).valid()
    }
  }
}
