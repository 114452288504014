$(document).on('turbo:load', () => {
  // disabling silver-medal checkbox when selecting the hired candidate
  $(document.body).on('change', '#hired-candidate-select', (event) => {
    // enable previously disabled checkboxes
    let form = $(event.target).closest("form")
    form.find('input:checkbox').prop('disabled', false);

    // disable checkbox of selected candidate
    let selected_id = $(event.target).val();
    $(`#job_application_tile_${selected_id}`).find(':checkbox').prop('checked', false).prop('disabled', true);
  });

  // submitting the fill position form from the confirmation modal
  $(document.body).on('click', '#fill-position', event => {
    event.preventDefault();
    $('#fill-job-vacancy-form').submit();
  });

  // submitting the close requisition form from the confirmation modal
  $(document.body).on('click', '#close-job-vacancy-submit', event => {
    event.preventDefault();
    $('#close-job-vacancy-form').submit();
  });
});
