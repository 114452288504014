import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dependent-checkbox"
export default class extends Controller {
  static targets = [ "independentCheckbox", "dependentCheckbox" ]
  static values = {
    dependsOn: String,
    name: String
  }

  connect() {
    this.toggleDependentCheckbox();
  }

  independentCheckboxClicked(_event) {
    this.toggleDependentCheckbox();
  }

  toggleDependentCheckbox() {
    let checked = this.independentCheckboxTarget.checked;

    if (checked) {
      $(this.dependentCheckboxTarget).prop('disabled', false)
    } else {
      $(this.dependentCheckboxTarget).prop('disabled', true).prop('checked', false)
    }

    // dispatch event for other controllers that may have checkboxes dependent on this one
    this.dispatch("independentCheckboxClicked", { detail: { checked: checked, name: this.nameValue } })
  }

  // update checkboxes from events outside of this controller
  toggleAdditionalIndependentCheckboxes(event) {
    let { checked, name } = event.detail;

    if (name == this.dependsOnValue) {
      if (checked) {
        $(this.independentCheckboxTarget).prop('disabled', false)
      } else {
        $(this.independentCheckboxTarget).prop('disabled', true).prop('checked', false)
        $(this.dependentCheckboxTarget).prop('disabled', true).prop('checked', false)
      }
    }
  }
}
