import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast"
export default class extends Controller {
  // data-toast-streaming-dom-id-value
  static values = { streamingDomId: String }

  connect() {
    // this is a DOM id guaranteed to be in the page that the controller action will cause to have an 'editor' data
    // attribute (see app/views/shared/toasts/suppress_reload_toast_for_editor.js.erb) that suppresses the toast from
    // being shown if the page is responsible for causing the toast
    let toastStreamingDomIdSelector = `#${this.streamingDomIdValue}`;

    // if you are not the editor then hide all other toasts and show this toast, otherwise do nothing
    if (!$(toastStreamingDomIdSelector).data('editor')) {
      $('.toast').toast('hide')
      $(this.element).toast('show')
    }

    // remove the 'editor' data attributes so that this page can show toasts from other page changes in the future
    $(toastStreamingDomIdSelector).removeData('editor')
  }
}
