import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-picker"
export default class extends Controller {
  static targets = [ "selectField", "associated" ]
  static values = {
    showOption: Number
  }

  toggleAssociated(event) {
    let value = $(event.target).val();

    if (value == this.showOptionValue) {
      $(this.associatedTarget).removeClass("hide")
    } else {
      $(this.associatedTarget).addClass("hide")
    }
  }
}
