import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-fetch"
export default class extends Controller {
  static targets = [ "selectField" ]
  static values = {
    param: String,
    url: String
  }

  selectFieldTargetConnected() {
    $(this.selectFieldTarget).on('change.select2', (_event) => {
      this.fetchUrl()
    });
  }

  fetchUrl() {
    let url = `${this.urlValue}?${this.paramValue}=${this.selectFieldTarget.value}`

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        'X-CSRF-Token': $.rails.csrfToken()
      },
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
