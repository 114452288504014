import { Controller } from "@hotwired/stimulus"

// solution from sstephenson to firing stimulus controller event on DOM change, which is the only thing you can do with turbostreams
export default class extends Controller {
  connect() {
    // notify an outer Stimulus controller that an update div has been inserted into it
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("update-occurred", true, true, null)
    this.element.dispatchEvent(event)
  }
}
