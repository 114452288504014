// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

require("@hotwired/stimulus")
require("@hotwired/turbo-rails")

require("channels")
require("controllers")
require('intro.js')

require("jquery-validation")
require("packs/src/turbo_load_event_hack")
require("packs/src/custom_validations")
require("packs/src/candidate")
require("packs/src/bulk_action_checkboxes") // also includes tooltip removal
require("packs/src/intl_tel_input_utils") // utils.js used by intl-tel-input package
require("packs/src/job_vacancy")
require("packs/src/role_definition")
require("packs/src/modal")
require("packs/src/question_magic")
require("packs/src/sortable_questions")
require("packs/src/single_section")
// require("packs/src/spinner") <- unused dashboard AJAX spinner code that is preserved in case we need it again
require("packs/src/reports")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import '../stylesheets/application.scss';

global.$ = jQuery;

// https://stackoverflow.com/a/8175979/912244
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

import "@fortawesome/fontawesome-free/js/all";

require("@nathanvda/cocoon")

require("smart_engine") // brings in trix and custom_action_text
global.moment = require('moment');
require("tempusdominus-bootstrap-4")
require("timepicker/jquery.timepicker")

require('signature_pad')
