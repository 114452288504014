$(document).on('show.bs.modal', function (event) {
  let button = $(event.relatedTarget) // Button that triggered the modal
  let fetch_path = button.data('fetch-path')
  let title = button.data('modal-title')

  // TODO: move this to js that is specific to the Fill/Close modals
  let hired_id = $('[name="job_vacancy[filled_job_application_id]"]').val()
  let selected_ids = [] // Javascript map does not work right, so we use each.
  $('[name="selected[]"]:checked').each(function() { selected_ids.push($(this).val()) } )

  let modal = $(button.data('target'))

  if (fetch_path) {
    if (hired_id) {
      fetch_path = fetch_path + `?hired_id=${hired_id}`
    }
    if (selected_ids.length > 0) {
      let sep = '?'
      if (fetch_path.includes(sep)) { sep = '&' }
      fetch_path = fetch_path + sep + `selected_ids=${selected_ids.join(',')}`
    }
    fetch(fetch_path)
      .then((data) => data.text())
      .then((html) => {
        if (title) {
          modal.find('.modal-title').html(title)
        }
        modal.find('.modal-body').html(html)
      });
  }
})
