import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="results-count"
export default class extends Controller {
  static targets = ["output", "form"];
  debounceTimeout = null; // Instance variable for debounce timeout

  connect() {
    this.updateCount();
  }

  formChanged() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.updateCount();
    }, 500); // Delay of 500 milliseconds  }
  }

  updateCount() {
    const formData = new FormData(this.formTarget);
    const searchParams = new URLSearchParams(formData).toString();
    const countUrl = this.formTarget.dataset.resultsCountUrl;
    const url = `${countUrl}?${searchParams}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.highlightChange();
        this.outputTarget.textContent = data.count;
      })
      .catch(error => console.error('Error:', error));
  }

  highlightChange() {
    this.outputTarget.parentNode.classList.add("bg-light");
    setTimeout(() => {
      this.outputTarget.parentNode.classList.remove("bg-light");
    }, 2000); // Highlight for 2 seconds
  }
}
