import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {
  static targets = [ "tagsField", "submitField", "multiField", "standardField" ]
  static values = {
    showSearch: { type: Boolean, default: true },
  }

  tagsFieldTargetConnected() {
    $(this.tagsFieldTarget).select2({
      createTag: function (params) {
        var term = $.trim(params.term);

        if (term === '') {
          return null;
        }

        return {
          id: 'topic-tag-' + term,
          text: term
        }
      },
      selectionCssClass: 'question-tags',
      matcher: function (params, data) {
        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '') {
          return data;
        }

        // Skip if there is no 'children' property
        if (typeof data.children === 'undefined') {
          return null;
        }

        // `data.children` contains the actual options that we are matching against
        var filteredChildren = [];
        $.each(data.children, function (idx, child) {
          if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
            filteredChildren.push(child);
          }
        });

        // If we matched any of the timezone group's children, then set the matched children on the group
        // and return the group object
        if (filteredChildren.length) {
          var modifiedData = $.extend({}, data, true);
          modifiedData.children = filteredChildren;

          // You can return modified objects from here
          // This includes matching the `children` how you want in nested data sets
          return modifiedData;
        }

        // Return `null` if the term should not be displayed
        return null;
      },
      tags: true,
      templateResult: function (data) {
        // We only really care if there is an element to pull classes from
        if (!data.element) {
          return data.text;
        }

        var $element = $(data.element);
        var $wrapper = $('<span></span>');
        $wrapper.addClass($element[0].className);
        $wrapper.text(data.text);

        return $wrapper;
      },
      templateSelection: function(data) {
        // We only really care if there is an element to pull classes from
        if (!data.element) {
          return data.text;
        }

        var $element = $(data.element);
        var $wrapper = $('<span></span>');
        // add default classes that assume we're adding a topic-tag when the className is blank
        $wrapper.addClass($element[0].className || 'topic-question-tag');
        $wrapper.text(data.text);

        return $wrapper;
      },
      theme: 'bootstrap4',
      width: '75%',
    })
  }

  tagsFieldTargetDisconnected() {
    $(this.tagsFieldTarget).select2('destroy');
  }

  multiFieldTargetDisconnected() {
    $(this.multiFieldTarget).select2('destroy');
  }

  standardFieldTargetDisconnected() {
    $(this.standardFieldTarget).select2('destroy');
  }

  submitFieldTargetConnected() {
    $(this.submitFieldTarget).on('select2:select', function (event) {
      event.currentTarget.form.submit()
    });
  }

  multiFieldTargetConnected() {
    $(this.multiFieldTarget).select2({
      multiple: true,
      theme: 'bootstrap4'
    })
  }

  standardFieldTargetConnected() {
    let minimumResultsForSearch = this.showSearchValue ? 0 : Infinity
    $(this.standardFieldTarget).select2({
      theme: 'bootstrap4',
      minimumResultsForSearch:  minimumResultsForSearch
    });
  }
}
