// custom methods and rules for jquery.validate

// required validation with a custom message
$.validator.addMethod("managerRequired", $.validator.methods.required, "Manager info is required unless you check the box above.");
$.validator.addMethod("waiverRequired", $.validator.methods.required, "If you are providing former manager information you must sign the waiver.");
$.validator.addMethod("preferredContactRequired", $.validator.methods.required, "You must have at least one preferred contact");
$.validator.addMethod("preferredInfoRequired", $.validator.methods.required, "You must provide info for preferred contact");
$.validator.addMethod("phoneInfoRequired", $.validator.methods.required, "Phone number is required");
$.validator.addMethod("phoneChannelRequired", function (_value, _element) {
  return $(".channel:visible").filter(function() {
    return ['phone', 'text'].includes(this.value)
  }).length > 0
}, "Phone or text contact is required");
$.validator.addMethod("timeMustBeLater", function (value, _element) {
  let startTime = moment($('.start-time').val(), 'hh:mm A');
  let endTime = moment(value, 'hh:mm A');
  return startTime.isBefore(endTime);
}, "End time must come after start time");

$.validator.addClassRules("manager-info", {
  managerRequired: {
    depends: function (element) {
      return !$(element).closest(".rater-info-opt-out").is(":checked");
    },
  },
});

$.validator.addClassRules("waiver-signed", {
  waiverRequired: {
    depends: function (_element) {
      return $('.manager-info').filter(function() { return $(this).val(); }).length > 0;
    },
  },
});

$.validator.addClassRules("preferred-contact", {
  preferredContactRequired: {
    depends: function (_element) {
      return $(".preferred-contact:visible:checked").length === 0;
    },
  },
});

$.validator.addClassRules("preferred-info", {
  preferredInfoRequired: {
    depends: function (element) {
      let checkbox = $(element).parents(".nested-fields").find('.preferred-contact')
      if (checkbox.is(":checked")) {
        return $(element).val().length == 0
      }
    },
  },
});

$.validator.addClassRules("phone-info", {
  phoneInfoRequired: {
    depends: function (element) {
      let channel = $(element).parents('.nested-fields').find('.channel').val()
      return ['phone', 'text'].includes(channel);
    },
  },
});

$.validator.addClassRules("phone-channel", {
  phoneChannelRequired: true,
});
