import Sortable from "sortablejs";

$(document).on('turbo:load', () => {
  let sortables = [];

  if ($(".sortable-questions").length > 0) {
    $(".sortable-questions").each((i, el) => {
      sortables.push(
        Sortable.create(el, {
          handle: '.sortable-handle',
          onUpdate: (event) => {
            let form = $("#sortable-form")
            $.rails.fire(form[0], 'submit');
          },
        })
      );
    })
  }
})
