function threeMonthsAgo() {
  let d = new Date;
  d.setMonth(d.getMonth() - 3);
  return d;
 }

 function yesterday() {
  let d = new Date;
  d.setDate(d.getDate() - 1);
  return d;
 }

 function hrefIncludeZeroRowsParam(a_tags, include_zero_rows) {
  a_tags.each(function() {
    href = this.href;

    if (include_zero_rows)
      href += "&include_zero_rows=true";
    else
      href = href.replace('&include_zero_rows=true', '');

    this.setAttribute('href', href);
  })
 }

$(document).on('turbo:load', () => {
  $('#datetimepicker-from').datetimepicker({
    defaultDate: threeMonthsAgo(),
    format: 'L'
  });

  $('#datetimepicker-to').datetimepicker({
    defaultDate: yesterday(),
    format: 'L',
    useCurrent: false
  });

  $("#datetimepicker-from").on("change.datetimepicker", function (e) {
    $('#datetimepicker-to').datetimepicker('minDate', e.date);
  });

  $("#datetimepicker-to").on("change.datetimepicker", function (e) {
    $('#datetimepicker-from').datetimepicker('maxDate', e.date);
  });

  $('#show_zero_rows').on('change', function() {
    let a_tags = $("#reports-table").find("a")

    if (this.checked) {
      hrefIncludeZeroRowsParam(a_tags, true);
      $('.zero_values_row').fadeIn('slow');
    } else {
      hrefIncludeZeroRowsParam(a_tags, false);
      $('.zero_values_row').fadeOut('slow');
    }
  });

  $('#download-csv-btn').on('click', function (e) {
    let button = $(this);
    button.attr('disabled', 'disabled');

    // Poll for the download cookie and replace the button with the download complete message
    let checkDownloadComplete = setInterval(function () {
      // Check the cookie or local storage value
      if (document.cookie.includes('download_started=true')) {
        clearInterval(checkDownloadComplete);

        button.remove();
        $('#csv-download-complete-msg').show()

        document.cookie = 'csv_download_started=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
    }, 1000); // Check every second
  });
});
