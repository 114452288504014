import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="question"
export default class extends Controller {
  static targets = [ "trix", "multipleAnswers", "answerPoints" ]
  static values = {
    unknownPoints: Number,
    knockoutPoints: Number
  }
  checkForMultiple(event) {
    const editor = this.trixTarget.editor
    let currentText = editor.getDocument().toString()
    let hasSelectAll = currentText.toLowerCase().includes('select all')
    let multipleAnswersChecked = this.multipleAnswersTarget.checked

    if (hasSelectAll && !multipleAnswersChecked) {
      let confirmed = confirm('You wrote "select all" in your question, but did not allow multiple answers. Save anyway?')

      if (!confirmed) {
        event.preventDefault()
      }
    }
  }

  answerPointsTargetConnected(element) {
    if ($(element).val() == this.unknownPointsValue) {
      // remove the UNKNOWN_POINTS_VALUE then validate to get an error notification
      $(element).val('')
      $(element).valid()
    }
  }

  assignKnockoutPoints(event) {
    // set points to KNOCKOUT_POINTS_VALUE, hide the points div, show the knockout div,
    let points_div = $(event.target).closest('.answer_option_points_div');
    let knockout_div = points_div.siblings('.answer_option_knockout_div')

    $(points_div).find(':text').val(this.knockoutPointsValue)
    points_div.removeClass('show')
    knockout_div.addClass('show')
  }

  removeKnockoutPoints(event) {
    // hide the knockout div, set points to 0, show the points div
    let knockout_div = $(event.target).closest('.answer_option_knockout_div');
    let points_div = knockout_div.siblings('.answer_option_points_div');

    points_div.find(':text').val(0)
    knockout_div.removeClass('show');
    points_div.addClass('show')
  }
}
