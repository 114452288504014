import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="funnel-counts"
export default class extends Controller {
  static targets = [ 'update' ]

  static values = {funnelKey: String, tileKey: String}

  connect() {
  }

  initialize() {
  }

  handleUpdate(event) {
    // optimization: only refresh when we know a future event targeting this tile is not going to do the same
    let future_events_count = $(event.target).siblings(`[data-target="funnel_counts.update"]`).length

    if (future_events_count == 0) {
      let updateEventData = $(event.target).data();

      this.refreshCounts(updateEventData);

      let fromTileKey = updateEventData['fromTileKey'];
      let toTileKey = updateEventData['toTileKey'];
      this.reloadData(fromTileKey);
      if (toTileKey != fromTileKey) { // only reload once for the same tile key
        this.reloadData(toTileKey);
      }
    }
    // delete the inserted event now that we've processed it
    $(event.target).remove();
  }

  // refreshes the counts on the tiles
  refreshCounts(updateEventData) {
    // we unconditionally call fetch counts because updates only come on streams for the model class we care about and
    // in order to get related funnel counts we need to call refresh_counts for every update event
    $.ajax({
      url: `/funnels/refresh_counts?funnel_key=${this.funnelKeyValue}`,
      type: 'GET'
    });
  }

  // refreshes the data in the turbo frame
  reloadData(tileKey) {
    // there may be cases where we prefer a toast to a reload; for now just always reload the data turbo frame
    let shouldReload = true
    let frame = $(`#${tileKey}-funnel-data`)

    // frame will only exist if the user is viewing the data for the given tileKey
    if (frame.length) {
      let toastContainer = $(`#${tileKey}-funnel-data-update-notice`);
      if (shouldReload) {
        // get the frame_src URL from the toast (can't use frame.attr('src') because it is null when the page is loaded)
        let frame_src = toastContainer.find('a:first').attr('href');
        frame_src +=  `&ut=${new Date().getTime()}` // append a unique timestamp to bust browser cache
        frame.attr('src', frame_src) // reload the frame
      } else {
        // reveal a toast with a button to reload the turbo frame
        toastContainer.removeClass('hide')
      }
    }
  }
}
