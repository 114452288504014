import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interceptor"
export default class extends Controller {

  // intercept all links and replace them with an alert message since most use mock IDs and are not reachable
  connect() {
    this.element.innerHTML = this.element.innerHTML.replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g, `<a href="#"`)
  }
}
