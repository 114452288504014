import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = [ "sequenceNumber", "sequenceLetter" ]

  connect() {
    let stimulusController = this

    $(this.element).each((i, el) => {
      Sortable.create(el, {
        draggable: ".nested-fields",
        handle: '.sortable-handle',
        filter: 'input[type=text], input[type=number], textarea', // prevent dragging when interacting with text and number inputs
        preventOnFilter: false, // allow normal interaction with filtered elements
        onUpdate: (evt) => {
          // update the sequence-number fields
          let seq = 1
          $(evt.to).find('.sequence-number').each(function () {
            this.value = seq++;
          });

          // update any sequence-letter HTML
          let letter = 'a'
          seq = 1
          $(evt.to).find('.sequence-letter').each(function () {
            this.innerHTML = stimulusController.letterForSequenceNumber(seq);
            seq++
          });
        },
      })
    })
  }

  letterForSequenceNumber(seqNum) {
    return String.fromCharCode('a'.charCodeAt(0) + seqNum - 1) + '.';
  }

  sequenceNumberTargetConnected(element) {
    if (element.value == "") {
      element.value = this.sequenceNumberTargets.length
    }
  }

  sequenceLetterTargetConnected(element) {
    if (element.innerHTML.trim() == ".") {
      element.innerHTML = this.letterForSequenceNumber(this.sequenceLetterTargets.length)
    }
  }
}
