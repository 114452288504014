$(document).on('turbo:load', () => {
  $('.js-state-select').select2({
    theme: 'bootstrap4',
  })
});

$(document).on('click', '.rater-info-opt-out', function () {
  // find the nearest rater-info-fields div and hide it when this checkbox is checked
  let checked = $(this).prop('checked');
  let collapsed_state = checked ? 'hide' : 'show';
  let rater_info_fields = $(this).closest('.form-row').siblings('.rater-info-fields').first();

  rater_info_fields.collapse(collapsed_state);
  rater_info_fields.find('.destroy-rater-info').val(checked);
  // clear fields in order to clear error message if they opt out
  rater_info_fields.find('.manager-info').val('');
});

$(document).on('click', '.to-date-select-toggler', function () {
  // find the nearest "to date" div and hide it when this checkbox is checked
  let checked = $(this).prop('checked');
  let target_div = $(this).closest('.form-inline').siblings('.to-date-select').first();

  if (checked) {
    target_div.find('select').val('')
  }

  target_div.toggleClass('hide', checked);
});

$(document).on('focus', '.select2.select2-container', function (e) {
  // only open on original attempt - close focus event should not fire open
  if (e.originalEvent && $(this).find(".select2-selection--single").length > 0) {
    $(this).siblings('select').select2('open');
  }
});

// fixes the autofocus of the select2 input field broken by jQuery 3.6.0
$(document).on('select2:open', event => {
  const id = event.target.id;
  const target = document.querySelector(`[aria-controls=select2-${id}-results]`);
  target.focus();
});

$(document).on("select2:close", event => {
  $(event.target.form).valid();
});

// reset form inputs on documents page when switching from selected, uploaded or linked documents
$(document).on('hidden.bs.tab', 'a.candidate-document[data-toggle="tab"]', function (event) {
  let input = $(event.target.hash).find('input, select');
  input.val('');
  // bs-custom-file-input requires a change event to clear the label text
  // https://github.com/Johann-S/bs-custom-file-input/issues/37
  input[0].dispatchEvent(new Event('change'))
})

// enable next button when the referral form is shown
$(document).on('shown.bs.collapse', '#referral-form', function (_event) {
  $('input[type=submit]').removeClass('hide')
})

// disable next button when the referral form is hidden
$(document).on('hidden.bs.collapse', '#referral-form', function (_event) {
  $('input[type=submit]').addClass('hide');
})
