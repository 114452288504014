import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="validation"
// Uses the https://jqueryvalidation.org/ library to validate forms
export default class extends Controller {
  connect() {
    let stimulusController = this

    $(this.element).find('select').on('change', function (event) {
      stimulusController.validator.element(event.target);
    });

    this.validator = $(this.element).validate({
      errorElement: 'div',
      errorClass: 'is-invalid',
      errorPlacement: function (error, element) {
        if (element.attr('type') == "checkbox") {
          if (element.hasClass('preferred-contact')) {
            element.closest('.nested-fields').append(error)
          } else {
            error.insertAfter(element.next('label'))
          }
        } else if (element.attr('type') == "file") {
          error.insertAfter(element.parents('.input-group'));
        } else if (element.hasClass('select2-hidden-accessible')) {
          parent = $("#select2-" + element.attr("id") + "-container").parent();
          error.insertAfter(parent);
        } else if (element.hasClass('datetimepicker-input') || element.attr('data-intl-tel-input-id') || element.next().hasClass('input-group-append')) {
          error.insertAfter(element.parent());
        } else {
          error.insertAfter(element);
        }
      },
      highlight: function (element, errorClass, _validClass) {
        let elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          $("#select2-" + elem.attr("id") + "-container").parent().addClass(errorClass);
        } else {
          elem.addClass(errorClass);
        }
      },
      unhighlight: function (element, errorClass, _validClass) {
        let elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
          $("#select2-" + elem.attr("id") + "-container").parent().removeClass(errorClass);
        } else {
          elem.removeClass(errorClass);
        }
      },
      onkeyup: function (element, _event) {
        this.element(element);
      },
      normalizer: function(value) {
        // Trim the value of the `field` element before
        // validating. this trims only the value passed
        // to the attached validators, not the value of
        // the element itself.
        return $.trim(value);
      }
    });
  }

  revalidate(event) {
    // https://jqueryvalidation.org/Validator.element/
    this.validator.element(event.params.field);
  }
}
